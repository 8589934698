<template>
  <div>
    <heard-and-footer>
      <title-name slot="title" my-name="联系我们"
                  :my-background="require('../../assets/image/lianxiwomeng.png')"></title-name>
      <div slot="content" class="content">
        <div class="map">
          <img style="width: 433px;margin-top: 150px;" src="../../assets/image/map.png"/>
        </div>
        <div class="from">
          <h3>联系我们</h3>
          <h5>Contact Us</h5>
          <div class="weContent">
            <div class="con">
              <h5>联系电话</h5>
              <p>029-8918-3673</p>
            </div>
            <div class="con">
              <h5>邮箱</h5>
              <p>xxxqfmxh@163.com</p>
            </div>
            <div class="addr">
              <h5>联系地址</h5>
              <p>陕西省西咸新区秦汉新城兰池大道中段秦汉文创大厦5层518室</p>
            </div>
          </div>
          <div class="input">
            <input placeholder="您的姓名">
            <input placeholder="您的电话">
            <textarea placeholder="留言内容"></textarea>
          </div>
          <button>提交</button>
        </div>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
export default {
  name: "connection",
  components:{
    heardAndFooter,
    TitleName,
  },
  data(){
    return{
      title:'联系我们',
    }
  },
  methods:{
    getTitleName(val){
      this.title=val
    }
  }
}
</script>

<style scoped>
.content{
  display: flex;
}
.map{
  margin: 1%;
  width: 48%;
  float: left;
  /*display: block;*/
}
.from{
  margin: 1%;
  width: 48%;
  float: left;
  display: block;
}
.weContent{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.weContent .con{
  width: 46%;
  margin: 3% 3% 3% 0;
  border-bottom: 1px solid #eeeeee;
}
.weContent .addr{
  width: 97%;
  margin: 1.5% 1.5% 1.5% 0;
  border-bottom: 1px solid #eeeeee;
}
.weContent h5{
  margin: 10px 0;
}
.input{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.input input{
  width: calc(44% - 22px);
  margin: 3% 3% 3% 0;
  padding: 0 10px;
  height: 40px;
  border: 1px #eeeeee solid;
}
.input textarea{
  width: calc(92% - 22px);
  margin: 3% 3% 3% 0;
  padding: 10px;
  height: 80px;
  border: 1px #eeeeee solid;
}
button{
  border: 1px #eeeeee solid;
  line-height: 30px;
  font-size: 14px;
  padding:10px 40px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
